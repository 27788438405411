import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBronzeDetails } from "../../store/slice/bronzeSlice";
import { getUserDetails } from "../../store/slice/userSlice";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, LinkedinShareButton, TelegramShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon, LinkedinIcon, TelegramIcon } from 'react-share';
import { slice } from "lodash";


const PopUpModals = ({
    showModal3, handleClose3,
    showModal4, handleClose4,
    showModal7, handleClose7,
    page, course_id, category_id,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { bronzeDetails: { data: bronzeDetails } } = useSelector((state) => state.bronze);
    const { userDetails: { data: userDetails } } = useSelector((state) => state.user);
    const [selectedBadge, setSelectedBadge] = useState(null);
    const [showSilverBadgeModal, setShowSilverBadgeModal] = useState(false);

    const handleBadgeClick = (badge) => {
        setSelectedBadge(badge);
        setShowSilverBadgeModal(true);
    }

    const handleSilverBadgeModalClose = () => {
        setShowSilverBadgeModal(false);
    }


    useEffect(() => {
        // if (!showModal1 && showModal1 !== false) {
        dispatch(getBronzeDetails());
        dispatch(getUserDetails());
        // }
    }, []);


    const bronzeBadge = userDetails?.nextBronzeData;
    const inputRef = useRef(null);

    const copyToClipboard = (value) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(value)
                .then(() => {
                    console.log(`Copied: ${value}`);
                    // You can also show a toast or alert here to notify the user.
                })
                .catch((err) => console.log("Failed to copy!", err));
        } else {
            console.log("Clipboard API not supported in this browser.");
        }
    };

    const shareUrl = userDetails?.referal_code;
    const title = "Check this out referral code!";
    const [showSharingOptions, setShowSharingOptions] = useState(false);

    const handleInviteNowClick = () => {
        setShowSharingOptions(!showSharingOptions);
    };

    const [arrBadges, setArrBadges] = useState([]);
    const [level, setLevel] = useState('');
    const [requiredLessons, setRequiredLessons] = useState(0);
    const [badge, setBadge] = useState('');

    // Function to reorder badges
    const reorderBadges = (arr) => {

        // Create a shallow copy of the array using slice() to avoid mutating the original array
        const copiedArr = arr.slice();

        // Sort the copied array in ascending order based on the 'id' field
        copiedArr.sort((a, b) => a.id - b.id);

        // Apply the reordering logic for every second row
        const tem = [];
        for (let i = 0; i < copiedArr?.length; i += 3) {
            const isFirstRow = i === 0;

            // Slice into groups of 3 badges and set the 'is_row_start' flag based on the row index
            const row = copiedArr.slice(i, i + 3).map((badge, idx) => ({
                ...badge,
                is_row_start: isFirstRow ? false : idx === 0 // Set 'false' for the first row and flag first item in other rows
            }));

            tem.push(row);
        }

        for (let j = 0; j < tem.length; j++) {
            if (j % 2 === 1) {
                tem[j].reverse();
            }
        }

        tem.forEach((subArray, index) => {
            if (index % 2 === 1) {
                while (subArray.length < 3) {
                    subArray.unshift(undefined);
                }
            }
        });
        return tem;
    };


    useEffect(() => {
        if (bronzeDetails && bronzeDetails.length > 0) {
            // Reordering badges
            const reorderedBadges = reorderBadges(bronzeDetails);
            setArrBadges(reorderedBadges);

            // Get last completed badge and next required badge
            const completedBadges = bronzeDetails.slice().reverse().filter(badge => badge.is_user_get_bronze === 1);
            const lastCompletedBadge = completedBadges[completedBadges.length - 1];

            const nextBadges = bronzeDetails.slice().reverse().filter(badge => badge.is_user_get_bronze === 0);
            const nextBadge = nextBadges[0];

            setLevel(lastCompletedBadge ? lastCompletedBadge.bronze_id : nextBadge?.bronze_id);
            setRequiredLessons(nextBadge?.required_lessons - nextBadge?.complete_lesson);
            setBadge(nextBadge?.bronze_name ?? null);
        }
    }, [bronzeDetails]);

    return (
        <>
            <Modal show={showModal3} onHide={handleClose3} size={"xl"} className="badge-detail-modal">
                <Modal.Body style={{ backgroundColor: "transparent" }}>
                    <div class="badge-details box_design-07">
                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-md-12 col-lg-4 cust-left-badgedetail position-relative">
                                    <div className="badge-details_left">
                                        <div className="content-badge-detail-left">
                                            <img src="./assets/img/icon-gain-badges.svg" alt="" className="img-fluid bronze-001" />
                                            <p className="fs-22 fw-6 dark-black text-center">{bronzeBadge?.bronze_name}</p>
                                            <div className="badge-points">
                                                <img src="./assets/img/star-rating.svg" alt="" className="star-001" />
                                                <p className="fs-14 fw-5 text-white"><span className="fw-7">{userDetails?.points}</span> Points</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-8 cust-right-badgedetail position-relative">
                    <div className="d-flex justify-content-end mx-4 mt-4">
                                <button type="button" className="btn-close" onClick={handleClose3} aria-label="Close"></button>
                                </div>

                                    {arrBadges.length > 0 && bronzeBadge && (
                                        <>
                                            <div className="silver-badge">
                                                <p className="fs-18 fw-4 dark black">
                                                    Required <span className="fw-7">{requiredLessons}</span> more lesson to unlock
                                                </p>
                                                <p className="fs-18 fw-7 carkblue">{badge} Badge</p>
                                            </div>

                                            {arrBadges.map((badgeRow, rowIndex) => (

                                                <div key={rowIndex} className={rowIndex % 2 === 0 ? 'bronze-silver_badges odd' : 'bronze-silver_badge-box-down bronze-silver_badges'}>
                                                    {badgeRow.map((badge, index) => (
                                                        badge ? (
                                                            <div key={index} className={`bronze-silver_badge-box position-relative ${badge.bronze_id <= level ? 'active' : 'inactive'}`} onClick={() => handleBadgeClick(badge)}>
                                                                <img src={badge.bronze_image_link} alt="" className="img-fluid badge_image2 active" />
                                                                <p className="fs-16 fw-6 dark-black text-center">{badge.bronze_name}</p>
                                                                <p className="fs-12 fw-4 gray1">
                                                                    <span className="fw-7">{badge.required_lessons} </span>lessons
                                                                </p>

                                                                {/* Horizontal line */}
                                                                {index < badgeRow.length - 1 && (
                                                                    <>
                                                                        {badge.bronze_id <= level ?
                                                                            <img src="./assets/img/linde-badge.png" alt="Horizontal Line" className="linde-badge active" />
                                                                            : <img src="./assets/img/line-badge-gray.png" alt="Horizontal Grey Line" className="linde-badge active" />
                                                                        }
                                                                    </>
                                                                )}

                                                                {/* Vertical line */}
                                                                {/* {arrBadges.length > 1 && badgeRow.length > 0  && ( */}
                                                                {/* {arrBadges.length > 1 && rowIndex >= arrBadges.length - 1 && index === badgeRow.length - 1 && ( */}
                                                                {/* {rowIndex !== 0 && arrBadges.length > 1 && index ===0 && ( */}
                                                                {badge.is_row_start && (
                                                                    <img src="./assets/img/line-badge-verticle.png" alt="Vertical Line" class="linde-badge-vertical" />
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div key={index} className="empty-badge bronze-silver_badge-box_empty position-relative inactive"></div>
                                                        )
                                                    ))}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            {/* <!-- Modal Silver Badge popup --> */}
            <Modal show={showSilverBadgeModal} onHide={handleSilverBadgeModalClose}>
                <Modal.Body>
                    <div className="theme-small-modal">
                        <img src={selectedBadge?.bronze_image_link} className="img-fluid" alt="" />
                        <h2>{selectedBadge?.bronze_name} Badge</h2>
                        <p>To get a {selectedBadge?.bronze_name} badge, you need to complete {selectedBadge?.required_lessons} lessons.</p>
                        <Button variant="theme" className="w-100 mt-5" onClick={handleSilverBadgeModalClose}>Ok</Button> {/* Handle closing */}
                    </div>
                </Modal.Body>
            </Modal>

            {/* <!-- Modal Refer & Earn Popup --> */}
            <Modal show={showModal4} onHide={handleClose4} className="fade modal-refer-earn" id="refer-earn_popup" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" size={'xl'}>
                <Modal.Dialog>
                    <Modal.Body className="p-0">
                        <div className="badge-details box_design-07" >
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-md-12 col-lg-6 cust-left-refer_earn position-relative">
                                        <div className="refer-earn-container">
                                            <div className="section-title refer-earn-box">
                                                <img src="assets/img/people1.svg" className="img-fluid img-left" alt="People" />
                                                <img src="assets/img/people2.svg" className="img-fluid img-right" alt="People" />
                                                <img src="assets/img/people3.png" className="img-fluid img-right-top" alt="People" />
                                                <p>Refer & Earn</p>
                                                <h3>Refer a friend and get a {userDetails?.refer_discount} discount on your next subscription</h3>
                                            </div>
                                            <div className="copyclip-board text-center" style={{ "borderBottomLeftRadius": "28px" }}>
                                                {/* <div className="position-relative box_design-clipboard w-75" onClick={copyToClipboard}>
                                                    <div className="referral-code-container w-100">
                                                        <input
                                                            ref={inputRef}
                                                            className="copy-input"
                                                            type="text"
                                                            value={userDetails?.referal_code || 'COGNITIVE20'}
                                                            readOnly
                                                        />
                                                        <span className="tap-to-copy gray1 fs-14 fw-4">Tap to copy</span>
                                                    </div>
                                                    <input className="copy-clip" type="button" value="" onClick={copyToClipboard} />
                                                </div> */}

                                                <div className="position-relative box_design-clipboard w-75 p-2 pb-3" onClick={() => copyToClipboard(userDetails?.referal_code || 'COGNITIVE20')}>
                                                    <div className="referral-code-container w-100">
                                                        <div className="cursor-pointer">
                                                            <div className="copy-text"> {userDetails?.referal_code || 'COGNITIVE20'}</div>
                                                            <div className="text-start gray1 fs-14 fw-4">Tap to copy</div>
                                                        </div>
                                                        <div>
                                                            <button className="copy-clip-btn" type="button" onClick={() => copyToClipboard(userDetails?.referal_code || 'COGNITIVE20')}></button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Button variant="theme" type="button" className="w-75" onClick={handleInviteNowClick}>Invite Now</Button>
                                                {showSharingOptions && (
                                                    <div className="sharing-options d-flex justify-content-between mx-5 mt-3">
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <FacebookShareButton url={shareUrl} quote={title}>
                                                                <FacebookIcon size={32} round />
                                                            </FacebookShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <TwitterShareButton url={shareUrl} title={title}>
                                                                <TwitterIcon size={32} round />
                                                            </TwitterShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <WhatsappShareButton url={shareUrl} title={title}>
                                                                <WhatsappIcon size={32} round />
                                                            </WhatsappShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <EmailShareButton url={shareUrl} title={title}>
                                                                <EmailIcon size={32} round />
                                                            </EmailShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <LinkedinShareButton url={shareUrl} title={title}>
                                                                <LinkedinIcon size={32} round />
                                                            </LinkedinShareButton>
                                                        </div>
                                                        <div className="sharing-icon border border-dark rounded-circle p-3 shadow-4">
                                                            <TelegramShareButton url={shareUrl} title={title}>
                                                                <TelegramIcon size={32} round />
                                                            </TelegramShareButton>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-6 cust-right-refer_earn position-relative">
                                        <div className="how-it-work">

                                            <h3 className="fs-20 fw-7 dark-black">How it works?</h3>
                                            <button type="button" className="btn-close" onClick={handleClose4} aria-label="Close"></button>
                                            <p className="fs-14 fw-7 dark-black text-01">
                                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                                                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                                Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                                                ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                                            </p>
                                            <p className="fs-14 fw-5 text-02">
                                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                                                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                                Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                                                ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                                            </p>
                                            <p className="fs-14 fw-5 text-02">
                                                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                                                Exercitation veniam consequat sunt nostrud amet.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>

            {/* <!-- Modal Currect Answer popup --> */}
            <Modal
                show={showModal7}
                onHide={handleClose7}
                centered
                aria-labelledby="exampleModalLabel"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Correct Answer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="theme-small-modal">
                        <img src="assets/img/currect-ans-icon.png" className="img-fluid" alt="Correct Answer Icon" />
                        <h2>Correct Answer</h2>
                        <p>
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
                            duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non
                            deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit
                            mollit. Exercitation veniam consequat sunt nostrud amet.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="theme" onClick={() => navigate('/practice-3', { state: { page, course_id, category_id } })}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PopUpModals;