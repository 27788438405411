import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";

export const getCountryList = createAsyncThunk("countryList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.getCountryList();
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getInterestList = createAsyncThunk("InterestList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.getInterestList();
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getSubList = createAsyncThunk("SubList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.getSubListApi();
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getCourseSearchList = createAsyncThunk(
  "getCourseSearchList",
  async ({ page, search }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const data = await API.searchCourse({ page, search });
      dispatch(setLoader(false));
      return data;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getNotificationList = createAsyncThunk(
  "getNotificationList",
  async ({ page }, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const data = await API.notificationListing({ page });
      dispatch(setLoader(false));
      return data;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);

export const getProgressReport = createAsyncThunk(
  "getProgressReport",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoader(true));
      const data = await API.progressReport();
      dispatch(setLoader(false));
      return data;
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  }
);


const initialState = {
  isLoading: false,
  isModalOpen: {
    modalType: "",
    isOpen: false,
    data: null,
  },
  countryList: {
    data: [],
    error: null,
  },
  InterestList: {
    data: [],
    error: null,
  },
  SubList: {
    data: [],
    error: null,
  },
  CourseSearchList: {
    data: [],
    error: null,
  },
  NotificationList: {
    data: [],
    error: null,
  },
  ProgressReport: {
    data: [],
    error: null,
  },
};

const masterSlice = createSlice({
  name: "MASTER_SLICE",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setModalStatus: (state, action) => {
      const { modalType, isOpen, data } = action.payload;
      state.isModalOpen.modalType = modalType;
      state.isModalOpen.isOpen = isOpen;
      state.isModalOpen.data = data;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.countryList.data = action.payload.data;
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.isLoading = false;
        state.countryList.error = action.error.message;
      })
      .addCase(getInterestList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.InterestList.data = action.payload.data;
      })
      .addCase(getInterestList.rejected, (state, action) => {
        state.isLoading = false;
        state.InterestList.error = action.error.message;
      })
      .addCase(getSubList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.SubList.data = action.payload.data;
      })
      .addCase(getSubList.rejected, (state, action) => {
        state.isLoading = false;
        state.SubList.error = action.error.message;
      })
      .addCase(getCourseSearchList.fulfilled, (state, action) => {
        state.CourseSearchList.data = action.payload.data;
      })
      .addCase(getCourseSearchList.rejected, (state, action) => {
        state.CourseSearchList.error = action.error.message;
      })
      .addCase(getNotificationList.fulfilled, (state, action) => {
        state.NotificationList.data = action.payload.data;
      })
      .addCase(getNotificationList.rejected, (state, action) => {
        state.NotificationList.error = action.error.message;
      })
      .addCase(getProgressReport.fulfilled, (state, action) => {
        state.ProgressReport.data = action.payload.data;
      })
      .addCase(getProgressReport.rejected, (state, action) => {
        state.ProgressReport.error = action.error.message;
      });
  },
});

export const { setLoader, setModalStatus } = masterSlice.actions;
export default masterSlice.reducer;
