import { configureStore } from "@reduxjs/toolkit";
import masterSlice from "./slice/masterSlice";
import userSlice from "./slice/userSlice";
import categorySlice from "./slice/categorySlice";
import cardSlice from "./slice/cardSlice";
import leaderboardSlice from "./slice/leaderboardSlice";
import downloadSlice from "./slice/downloadSlice";
import courseSlice from "./slice/courseSlice";
import lessionSlice from "./slice/lessionSlice";
import practiceSlice from "./slice/practiceSlice";
import bronzeSlice from "./slice/bronzeSlice";
import myCourseSlice from "./slice/myCourseSlice";

const store = configureStore({
  reducer: {
    master: masterSlice,
    category: categorySlice,
    user: userSlice,
    card: cardSlice,
    leaderboard: leaderboardSlice,
    download: downloadSlice,
    course: courseSlice,
    lession: lessionSlice,
    practice: practiceSlice,
    bronze: bronzeSlice,
    myCourse: myCourseSlice,
  },

});

export default store;