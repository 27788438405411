export const AWS_BASE_URL = process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_AWS_BASE_URL_LIVE : process.env.REACT_APP_AWS_BASE_URL;

export const AWS_STORAGE_BUCKET_NAME = process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME_LIVE : process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME;

export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_AWS_ACCESS_KEY_ID_LIVE : process.env.REACT_APP_AWS_ACCESS_KEY_ID;

export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_LIVE : process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

export const AWS_S3_REGION_NAME = process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_AWS_S3_REGION_NAME_LIVE : process.env.REACT_APP_AWS_S3_REGION_NAME;

export const API_KEY = process.env.REACT_APP_API_KEY;

export const BASE_URL = process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_BASE_URL_LIVE : process.env.REACT_APP_BASE_URL;
console.log("process.env.REACT_APP_SERVER_MODE---------------    ",process.env.REACT_APP_SERVER_MODE)

export const STRIPER_PAYMENT_TEST_KEY = process.env.REACT_APP_SERVER_MODE == 'LIVE' ? process.env.REACT_APP_STRIPER_PAYMENT_TEST_KEY_LIVE : process.env.REACT_APP_STRIPER_PAYMENT_TEST_KEY

export const ALPHABETS = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode(65 + i)
);


export const STATUS_CLASS = {
  pending: "warning",
  ongoing: "primary",
  room_started: "primary",
  winnings_calculating: "info",
  auto_cancel: "danger",
  auto_rejected: "danger",
  cancel: "danger",
  completed: "success",
  approved: "success",
  rejected: "danger",
  stop: "danger",
  Escalate: "danger",
  escalate: "danger",
  delivered: "success",
  dispatch: "info",
  resolved: "success",
  success: "success",
  failed: "failed",
  verified: "success",
  verify: "primary",
  request: "warning",
  accept: "success",
  blocked: "danger",
  accepted: "success",
};

// MODAL TYPES
export const COUNTRY_MODAL = "COUNTRY_MODAL";
export const GAME_TYPE_MODAL = "GAME_TYPE_MODAL";
export const TOURNAMENT_TYPE_MODAL = "TOURNAMENT_TYPE_MODAL";
export const DEVICE_BANNED = "DEVICE_BANNED";
export const IP_BANNED = "IP_BANNED";
export const REASON_FOR_DEACTIVE = "REASON_FOR_DEACTIVE";
export const REASON_FOR_ACTIVE = "REASON_FOR_ACTIVE";
export const EDIT_CASH_BALANCE = "EDIT_CASH_BALANCE";
export const EDIT_WINNING_BALANCE = "EDIT_WINNING_BALANCE";
export const EDIT_BONUS_BALANCE = "EDIT_BONUS_BALANCE";
export const EDIT_TOTAL_WIN_BALANCE = "EDIT_TOTAL_WIN_BALANCE";
export const EDIT_TOPUP_BALANCE = "EDIT_TOPUP_BALANCE";
export const ADD_CARD = "ADD_CARD";
export const EDIT_CARD = "EDIT_CARD";
export const DELETE_MODAL = "DELETE_MODAL";
export const EDIT_POST = "EDIT_POST";
export const EDIT_SPOTLIGHT_MESSAGE = "EDIT_SPOTLIGHT_MESSAGE";
export const ADD_GROUP = "ADD_GROUP";
export const SCHEDULE_EMAIL_TO_USER = "SCHEDULE_EMAIL_TO_USER";
export const SCHEDULE_NOTIFICATION_TO_USER = "SCHEDULE_NOTIFICATION_TO_USER";
export const ADD_SPOTLIGHT = "ADD_SPOTLIGHT";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_PRO_VERIFICATION_REQUEST =
  "UPDATE_PRO_VERIFICATION_REQUEST";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const ACCEPT_CHAT_SUPPORT = "ACCEPT_CHAT_SUPPORT";
export const CHAT_GROUP_MODAL = "CHAT_GROUP_MODAL";
export const ADD_MEMBERSHIP_FEATURE = "ADD_MEMBERSHIP_FEATURE";

// HTTPS requests
export const GET = "get";
export const POST = "post";
export const PUT = "put";
export const PATCH = "patch";
export const DELETE = "delete";
export const OPTION = "options";

//constants
export const USER = "user";
export const GAMES = "games";
export const DATE_FORMAT = "YYYY-MM-DD";
export const ABBREVIATED_DATE_FORMAT = "D MMM";
export const ABBREVIATED_FULL_DATE_FORMAT = "D MMM YYYY";
export const DATE_TIME_FORMAT = "DD MMM YYYY, hh:mm A";
export const DATE_TIME_MONTHE_WISE_FORMAT = "MMM DD YYYY, hh:mm A";
export const DATE_DASH_TIME_FORMAT = "DD-MM-YYYY h:mm A";
export const DATE_YEAR_WISE_DASH_TIME_FORMAT = "YYYY-MM-DD h:mm A";
export const DATE_LOCAL_DASH_TIME_FORMAT = "YYYY-MM-DDTHH:mm";
export const DATE_DOT_TIME_FORMAT = "DD.MM.YYYY H:mm";
export const DATE_SLASH_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "hh:mm A";
export const TIME_FORMAT_24 = "HH:mm";
export const SEARCH_DELAY = 500;
export const ITEM_PER_PAGE = 9;
export const COUNT_PER_PAGE = 9;
