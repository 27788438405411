import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getCategoryList = createAsyncThunk("categoryList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const data = await API.categoryList();
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getFeturedCourse = createAsyncThunk("feturedCourseList", async (page, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const data = await API.feturedCourseList({ page });
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getCategoryWiseCourseList = createAsyncThunk("categoryWiseCourseList", async ({page,category_id}, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    const data = await API.categorywiseCourseList({page,category_id});
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  categoryList: {
    data: [],
    error: null,
  },
  feturedCourseList: {
    data: [],
    error: null,
  },
  categoryWiseCourseList: {
    data: [],
    error: null,
  },
};

const categoryListSlice = createSlice({
  name: "Categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.categoryList.data = action.payload.data;
      })
      .addCase(getCategoryList.rejected, (state, action) => {
        state.categoryList.error = action.error.message;
      })
      .addCase(getFeturedCourse.fulfilled, (state, action) => {
        state.feturedCourseList.data = action.payload.data;
      })
      .addCase(getFeturedCourse.rejected, (state, action) => {
        state.feturedCourseList.error = action.error.message;
      })
      .addCase(getCategoryWiseCourseList.fulfilled, (state, action) => {
        state.categoryWiseCourseList.data = action.payload.data;
      })
      .addCase(getCategoryWiseCourseList.rejected, (state, action) => {
        state.categoryWiseCourseList.error = action.error.message;
      });
  },
});

export default categoryListSlice.reducer;
