import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getCardDetails = createAsyncThunk("cardDetails", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.showCard();
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  cardDetails: {
    data: {},
    error: null,
  }
};

const cardSlice = createSlice({
  name: "CARD",
  initialState,
  reducers: {
    setCardDetails: (state) => {
      state.userDetails.data = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardDetails.fulfilled, (state, action) => {
        state.cardDetails.data = action.payload.data;
      })
      .addCase(getCardDetails.rejected, (state, action) => {
        state.cardDetails.error = action.error.message;
      })
  }
});

export const { setCardDetails } = cardSlice.actions;
export default cardSlice.reducer;
