import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getPracticeDetails = createAsyncThunk("PracticeDetails", async ({chapter_id,category_id,course_id,lesson_id,type}, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.practiceList({chapter_id,category_id,course_id,lesson_id,type});
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

export const getPracticeAnswer = createAsyncThunk("PracticeAnswer", async ({chapter_id,category_id,course_id,lesson_id}, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.practice_answer_list({chapter_id,category_id,course_id,lesson_id});
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  PracticeDetails: {
    data: {},
    error: null,
  },
  PracticeAnswer: {
    data: {},
    error: null,
  },
};

const practiceSlice = createSlice({
  name: "Practice",
  initialState,
  reducers: {
    setPracticeDetails: (state) => {
      state.PracticeDetails.data = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPracticeDetails.fulfilled, (state, action) => {
        state.PracticeDetails.data = action.payload.data;
      })
      .addCase(getPracticeDetails.rejected, (state, action) => {
        state.PracticeDetails.error = action.error.message;
      })
      .addCase(getPracticeAnswer.fulfilled, (state, action) => {
        state.PracticeAnswer.data = action.payload.data;
      })
      .addCase(getPracticeAnswer.rejected, (state, action) => {
        state.PracticeAnswer.error = action.error.message;
      })
  }
});

export const { setPracticeDetails } = practiceSlice.actions;
export default practiceSlice.reducer;
