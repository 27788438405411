import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getBronzeDetails = createAsyncThunk("bronzeDetails", async (_, { dispatch }) => {
    try {
        dispatch(setLoader(true));
        let data = await API.getBronzeDetails();
        dispatch(setLoader(false));
        return data;
    } catch (error) {
        dispatch(setLoader(false));
        throw error;
    }
});

const initialState = {
    bronzeDetails: {
        data: {},
        error: null,
    }
};

const bronzeSlice = createSlice({
    name: "BRONZE",
    initialState,
    reducers: {
        setBronzeDetails: (state) => {
            state.bronzeDetails.data = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBronzeDetails.fulfilled, (state, action) => {
                state.bronzeDetails.data = action.payload.data;
            })
            .addCase(getBronzeDetails.rejected, (state, action) => {
                state.bronzeDetails.error = action.error.message;
            })
    }
});

export const { setBronzeDetails } = bronzeSlice.actions;
export default bronzeSlice.reducer;
