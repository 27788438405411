import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getLessionDetails = createAsyncThunk("LessionDetails", async ({chapter_id,category_id,course_id}, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.lessonList({chapter_id,category_id,course_id});
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  LessionDetails: {
    data: {},
    error: null,
  }
};

const lessionSlice = createSlice({
  name: "Lession",
  initialState,
  reducers: {
    setLessionDetails: (state) => {
      state.LessionDetails.data = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLessionDetails.fulfilled, (state, action) => {
        state.LessionDetails.data = action.payload.data;
      })
      .addCase(getLessionDetails.rejected, (state, action) => {
        state.LessionDetails.error = action.error.message;
      })
  }
});

export const { setLessionDetails } = lessionSlice.actions;
export default lessionSlice.reducer;
