import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../store/slice/masterSlice';
import { TOAST_ERROR } from '../../utils/common.service';
import * as API from "../../utils/api.service";
import backArrow from "../../back_arrow.png";
import { useNavigate } from 'react-router-dom';
const TermsOfUse = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [staticData, setStaticData] = useState(null);
    console.log('staticData :', staticData);
    useEffect(() => {
        const fetchStaticData = async () => {
            try {
                dispatch(setLoader(true));
                const response = await API.staticPages();
                dispatch(setLoader(false));
                setStaticData(response?.data?.terms_condition_content);
            } catch (error) {
                dispatch(setLoader(false));
                TOAST_ERROR(error.message);
            }
        };
        fetchStaticData();
    }, []);
    return (
        
          <>
          <div className="d-flex align-items-center">
              <img src={backArrow} height={25} width={25} className='m-5' onClick={() => navigate(-1)} />
              <h2 className="title-26px ml-2">Terms of use</h2>
          </div>
          <div className="content_aboutus">
              {staticData && <div dangerouslySetInnerHTML={{ __html: staticData }} />}
          </div>
      </>
    );
}

export default TermsOfUse;
