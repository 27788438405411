import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getMyCourseDetails = createAsyncThunk("MyCourseDetails", async (page, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.myCourseDetails({page});
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  MyCourseDetails: {
    data: {},
    error: null,
  }
};

const myCourseDetailsSlice = createSlice({
  name: "MyCourseDetails",
  initialState,
  reducers: {
    setMyCourseDetailsSlice: (state) => {
      state.MyCourseDetails.data = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyCourseDetails.fulfilled, (state, action) => {
        state.MyCourseDetails.data = action.payload.data;
      })
      .addCase(getMyCourseDetails.rejected, (state, action) => {
        state.MyCourseDetails.error = action.error.message;
      })
  }
});

export const { setMyCourseDetailsSlice } = myCourseDetailsSlice.actions;
export default myCourseDetailsSlice.reducer;
