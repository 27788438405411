
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PopUpModals from "../PopUpModals";
import SubscriptionModal from "../PopUpModals/SubscriptionModal";

const LeftSideBar = () => {
    const location = useLocation();
    const [showModal2, setShowModal2] = useState(false);
    const handleShow2 = () => setShowModal2(true);
    const handleClose2 = () => {
        setShowModal2(false);
    }
    const activeLink = location.pathname.split('/')[1];
    return (
        <div className="col-lg-12 col-md-1 cust_aside-left">
            <div className="asidebar-left">
                <ul>
                    <li>
                        <Link to="/dashboard" className={activeLink === "dashboard" ? "active" : ""}>
                            <div className="icon-box">
                                <img src={activeLink == "dashboard" ? "./assets/img/home/dashboard-active.svg" : "./assets/img/home/dashboard.svg"} alt="dashboard icon" className={activeLink == "dashboard" ? "icon-leftasidebar active" : "icon-leftasidebar"} />
                                <span className="name">
                                    Dashboard
                                </span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link to="/my-courses" className={activeLink === "my-courses" ? "active" : ""}>
                            <div className="icon-box">
                                <img src={activeLink === "my-courses" ? "./assets/img/home/mycourse-actice.svg" : "./assets/img/home/mycourse.svg"} alt="my courses icon" className={activeLink === "my-courses" ? "icon-leftasidebar active" : "icon-leftasidebar"} />
                                <span className="name">
                                    My Courses
                                </span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link to="/leaderboard" className={activeLink === "leaderboard" ? "active" : ""}>
                            <div className="icon-box">
                                <img src={activeLink === "leaderboard" ? "./assets/img/home/leaderboard-active.svg" : "./assets/img/home/leaderboard.svg"} alt="leaderboard icon" className={activeLink === "leaderboard" ? "icon-leftasidebar active" : "icon-leftasidebar"} />
                                <span className="name">
                                    Leaderboard
                                </span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <button onClick={() => handleShow2()} className={`bg-transparent border-0 m-0 p-0 ${activeLink === "subscription" ? "active" : ""}`}>
                            <div className="icon-box">
                                <img src={activeLink === "subscription" ? "./assets/img/home/premium-active.svg" : "./assets/img/home/premium.svg"} alt="subscription icon" className={activeLink === "subscription" ? "icon-leftasidebar active" : "icon-leftasidebar"} />
                                <span className="name">
                                    Subscription
                                </span>
                            </div>
                        </button>
                    </li>

                    <li>
                        <Link to="/myprofile" className={activeLink === "myprofile" ? "active" : ""}>
                            <div className="icon-box">
                                <img src={activeLink === "myprofile" ? "./assets/img/home/setting-actice.svg" : "./assets/img/home/setting.svg"} alt="settings icon" className={activeLink === "myprofile" ? "icon-leftasidebar active" : "icon-leftasidebar"} />
                                <span className="name">
                                    Settings
                                </span>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
            <SubscriptionModal showModal2={showModal2} handleClose2={handleClose2} />
        </div>
    )
}

export default LeftSideBar;