import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "../../../utils/api.service";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../utils/common.service";
import { getSubList, setLoader } from "../../../store/slice/masterSlice";
import BillIngModal from "../BillIngModal";
import { getUserDetails } from "../../../store/slice/userSlice";


const SubscriptionModal = ({
    showModal2, handleClose2
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { SubList: { data: SubList } } = useSelector((state) => state.master);


    const { userDetails: { data: userDetails } } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getUserDetails());
    }, []);

    const [billData, setBillData] = useState()
    const [showBill, setShoBill] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [userSelectedPlan, setUserSelectedPlan] = useState(0);

    const handleShowBill = () => {
        setShoBill(true);
    }
    const handleBillClose = () => {
        setShoBill(false);
    }

    useEffect(() => {
        const userPurchasedPlan = userDetails?.planPurchaseData;

        // Check if the necessary data exists before updating the state
        if (userPurchasedPlan && userPurchasedPlan.subscription_plan_id && userPurchasedPlan.pricing && userPurchasedPlan.plan_name) {

            const newPlan = `${userPurchasedPlan.subscription_plan_id}:${userPurchasedPlan.pricing}:${userPurchasedPlan.plan_name}`;
            setSelectedPlan(newPlan);
            setUserSelectedPlan(userPurchasedPlan.subscription_plan_id);
        } else {
            setSelectedPlan(null);
        }

    }, [userDetails]);

    const handleCheckboxChange = (e) => {
        setSelectedPlan(e.target.value);
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        trigger
    } = useForm({
        defaultValues: {
            plan_name: [],
        },
    });

    const onSubmit = () => {
        const [id, pricing, plan_name] = selectedPlan?.split(":");
        handleClose2();
        handleShowBill();
        setBillData({ id, pricing, plan_name });
    };

    const handleSkip = () => {
        navigate('/dashboard')
        handleClose2();
    };

    useEffect(() => {
        dispatch(getSubList())
    }, []);

    const handleSubCheckboxChange = (value) => {
        setSelectedPlan(value.trim());
    };


    return (
        <>
            {/* <!-- Modal subscription-popup --> */}
            <Modal show={showModal2} onHide={handleClose2} size="xl" className="theam-subscription" id="firstModal">
                {/* <Modal.Header>
                    <Button variant="link" onClick={handleSkip} style={{ marginLeft: 'auto' }}>Skip</Button>
                </Modal.Header> */}
                <Modal.Body className="p-0">
                    <div className="subscription-section">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-12">
                                <div className="bg-logo-blue">
                                    <img src="assets/img/logo-white-large.svg" className="img-fluid" alt="Logo" />
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-12">
                                <div className="d-flex justify-content-end mx-4 mt-4">
                                    {/* <Button variant="link" onClick={handleSkip} style={{ marginLeft: 'auto' }}>Skip</Button> */}
                                    <button type="button" className="btn-close" onClick={handleClose2} aria-label="Close"></button>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-xl-9 col-lg-10 col-12">
                                        <div className="choose-your-plan pt-0">

                                            <div className="login-title text-center mb-5">
                                                <h3>Choose your plan</h3>
                                                <p>Select the plan that works for you</p>
                                            </div>

                                            <div className="login-tabs">
                                                <form className="common_form" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="row subscription-lists justify-content-center">
                                                        {SubList && SubList.map(({ id, plan_name, pricing, duration }, index) =>
                                                            <div key={index} className="mb-4 col-lg-6 col-md-6 col-12">
                                                                
                                                                <div className={`subscription-list form-check ${selectedPlan == `${id}:${pricing}:${plan_name}` ? 'active' : ''}`}>
                                                                    <label className="form-check-label" htmlFor={id} onClick={() => handleSubCheckboxChange(`${id}:${pricing}:${plan_name}`)}>

                                                                        <div className="select-acctype_container">
                                                                            <ul>
                                                                                <li className="select-acctype">
                                                                                    <input
                                                                                        {...register("plan_name", { required: "Please select plan" })}
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        id={id}
                                                                                        name="plan_name"
                                                                                        value={`${id}:${pricing}:${plan_name}`}
                                                                                        checked={selectedPlan == `${id}:${pricing}:${plan_name}`} // This checks if the plan is selected
                                                                                        onChange={() => handleSubCheckboxChange(`${id}:${pricing}:${plan_name}`)} // Updates the selected plan
                                                                                    />
                                                                                    <div className="left">
                                                                                        <div className="subscription-head">
                                                                                            <h3>{plan_name} Plan</h3>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="subscription-body">
                                                                            <h3>{pricing} $<span>/{duration}</span></h3>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {errors.plan_name && (
                                                            <p role="alert" className="text-danger">
                                                                {errors.plan_name?.message}
                                                            </p>
                                                        )}
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-12">
                                                            <div className="features-main">
                                                                <h3>Features</h3>
                                                                <ul>
                                                                    {SubList && SubList?.map(({ features, index }) =>
                                                                        <li key={index}><p><img src="assets/img/icon-tick-circle.svg" alt="tick" className="img-fluid pe-2" />  {features?.map((e) => e?.feature)}</p></li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row justify-content-center mb-5 mt-5">
                                                        <div className="col-lg-12 col-md-12 col-12">
                                                            <button type="submit" className="btn btn-theme w-100">Continue</button>
                                                        </div>
                                                    </div>

                                                    <div className="row justify-content-center">
                                                        <div className="small-footer-link">
                                                            <ul>
                                                                <li><Link to="/termsofuse" target="_blank">Terms & Conditions</Link></li>
                                                                <li><Link to="/privacy-policy" target="_blank">Privacy Policy</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body >
            </Modal>

            <BillIngModal
                showBill={showBill}
                handleBillClose={handleBillClose}
                billData={billData}
            />

        </>
    )
}

export default SubscriptionModal;