import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import ScreenSuccess from '../PopUpModals/ScreenSucessModal';
import { TOAST_SUCCESS } from '../../utils/common.service';
import * as API from "../../utils/api.service";
import { STRIPER_PAYMENT_TEST_KEY } from '../../app.config';

const stripePromise = loadStripe(STRIPER_PAYMENT_TEST_KEY);


const CheckoutForm = ({ billData, handleBillClose }) => {
    // console.log(STRIPER_PAYMENT_TEST_KEY,"REACT_APP_STRIPER_PAYMENT_TEST_KEY");
    // console.log(billData.pricing, "billcheckoutform");
    
    const stripe = useStripe();
    const navigate = useNavigate();
    const elements = useElements();
    const location = useLocation();

    const [error, setError] = useState(null);

    const [modalIsOpen, setIsOpen] = useState(false);
    const handleClose1 = () => {
        setIsOpen(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.createToken(elements.getElement(CardElement), { name: Cookies.get('PAemail') });

        if (result.error) {
            setError(result.error.message);
        } else {
            setError(null);
            const requestbody = {
                subscription_id: billData?.id,
                pricing: billData?.pricing,
                card_detail: result?.token
            }
            
            let { data, code, message } = await API.addSubscription(requestbody);
            
            if (code === '1') {
                setIsOpen(true);
                TOAST_SUCCESS(message);
            }
        }
    };

    return (
        <>
            {/* <Helmet>
                <link href="assets/css/stripe.css" rel="stylesheet" />
            </Helmet> */}
            <form onSubmit={handleSubmit} className="theme_form">
                <div className="custom-payment-form">
                    <div className="form-group">
                       
                        <div className='mb-5'>
                            <CardElement options={{
                                style: {
                                    base: {
                                        fontSize: '18px',
                                        '::placeholder': {
                                            color: '#545454',
                                        },
                                        ':-webkit-autofill': {
                                            color: '#e39f48',
                                        },
                                        '::selection': {
                                            background: '#0074cc',
                                            color: '#fff',
                                        },
                                    },
                                },
                            }} />
                            {error && <label className="error-message errorc">{error}</label>}
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-lg-12 col-md-12 col-12">
                            <button type="submit" className="btn btn-theme w-100" >Confirm & Pay</button>
                        </div>
                    </div>
                </div>
            </form>

            <Modal isOpen={modalIsOpen} ariaHideApp={false}>
                <ScreenSuccess
                    message={`Your order has been successfully placed.`}
                    title={`Congratulations!`}
                    setIsOpen={setIsOpen}
                    handleBillClose={handleBillClose}
                />
            </Modal>
        </>
    );
};

const StripePayment = ({ billData, handleBillClose }) => {
    return (
        <Elements stripe={stripePromise} >
            <CheckoutForm billData={billData} handleBillClose={handleBillClose} />
        </Elements>
    );
};

export default StripePayment;
