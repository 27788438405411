import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as API from "../../utils/api.service";
import Cookies from "js-cookie";
import { TOAST_ERROR } from "../../utils/common.service";
import { getCategoryList, getCategoryWiseCourseList } from "../../store/slice/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../hooks/useDebounce";
import { SEARCH_DELAY } from "../../app.config"
import { getCourseSearchList, getNotificationList, setLoader } from "../../store/slice/masterSlice";
import InterestModal from "../PopUpModals/InterestModal";

const CommonHeader = ({ c_id = null }) => {
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { categoryList: { data: categoryList } } = useSelector((state) => state.category);
    const { NotificationList: { data: NotificationList } } = useSelector((state) => state.master);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategoryName, setSelectedCategoryName] = useState('All Categories');
    const [filters, setFilters] = useState({ search: "" });
    const deb_value = useDebounce(filters.search, SEARCH_DELAY);
    const seach_val = location.search.split('=')[1];
    const [showModal6, setShowModal6] = useState(false);
    const { userDetails: { data: userDetails } } = useSelector((state) => state.user);
    const state_category_id = location?.state;

    // console.log('userDetails :', userDetails);
    const handleClose6 = (formData) => {
        setShowModal6(false);
    }
    const handleShow6 = () => setShowModal6(true);

    useEffect(() => {
        if (c_id) {
            setSelectedCategory(c_id)
        }
    }, [c_id])

    const handleCategoryChange = (category) => {

        if (category === "") {
            setSelectedCategory(''); // Reset selected category
            setSelectedCategoryName('All Categories');
            navigate('/dashboard');
        } else {
            const category_id = category.category_id;
            setSelectedCategory(category_id);
            // setSelectedCategoryName(category.name);

            navigate('/categorywisecourse', { state: category_id });
        }
    };

    useEffect(() => {

        const category = categoryList.find((value) => value.category_id === state_category_id);
        const categoryName = category ? category.name : null; // Use null or a default value if not found

        // Set the selected category name or default to 'All Categories'
        setSelectedCategoryName(categoryName || 'All Categories');
    }, [state_category_id, categoryList]);

    useEffect(() => {

        // Initial setup for the category name, if needed
        const category = categoryList.find((value) => value.category_id === state_category_id);
        const categoryName = category ? category.name : null; // Use null or a default value if not found

        // Set the selected category name or default to 'All Categories'
        setSelectedCategoryName(categoryName || 'All Categories');
    }, []); // This will run only once when the component mounts

    const handleSearch = ((e) => {
        if (e.target.value == '') {
            // navigate('/dashboard');
            navigate(-1); // Go back to the previous page
            setFilters({ search: '' });
        } else {
            setFilters({ search: e.target.value });
        }
    });

    const handleLogout = async () => {
        try {
            dispatch(setLoader(true));
            Cookies.set('isInterest', false, { expires: 2 });
            await API.logout();
            dispatch(setLoader(false));
            localStorage.removeItem("formData");
            Cookies.remove('isLoginCA');
            Cookies.remove('dataCA');
            Cookies.remove('tokenCA');
            navigate("/login");
        } catch (err) {
            dispatch(setLoader(false));
            TOAST_ERROR(err.message);
        }
    };

    // const viewNotifications = () => {
    //     dispatch(getNotificationList({ page }));
    // }

    const viewAllNotifications = () => {
        navigate('/viewnotification');
    }

    // console.log('userDetails?.profile_image :', userDetails?.profile_image);
    const renderDateTime = (datetime) => {
        const date = new Date(datetime);

        if (isNaN(date.getTime())) {
            return <div>Invalid Date</div>;
        }

        const formattedDate = date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        });

        return <span style={{ color: "#26ADFF" }}>{formattedDate}</span>;
    }
    useEffect(() => {
        dispatch(getNotificationList({ page }));
    }, [])

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         dispatch(getNotificationList({ page }));
    //     }, 10000); // 10 seconds

    //     return () => clearInterval(intervalId); // Cleanup on component unmount
    // }, [dispatch, page]); // Add dependencies if page can change

    useEffect(() => {
        const timer = setTimeout(() => {
            if (deb_value.trim() !== '') {
                navigate(`/searchcategory?search=${encodeURIComponent(deb_value)}`);
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [deb_value, history]);

    useEffect(() => {
        dispatch(getCategoryList());
    }, []);



    return (
        <>
            <header className="header-main">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/"><img src="assets/img/logo.svg" className="img-fluid" alt="logo" /></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item all-categories header-category-dropdown head-profile-notification">
                                    <div className="btn-group" style={{ float: "right" }}>
                                        <a className="btn-profile" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{selectedCategoryName}
                                            <img src="assets/img/icon-arrow-down-gray.svg" className="img-fluid" /></a>
                                        <div className="dropdown-menu dropdown-menu-lg-end">
                                            <div className="chat-main-section">
                                                <div className="chat-body">
                                                    <li
                                                        className="chat-list p-1 cursor-pointer"
                                                        onClick={() => handleCategoryChange('')}
                                                    >
                                                        <div className="chat-info">
                                                            <img
                                                                src={"./assets/img/home/chapter.svg"}
                                                                className="img-fluid chat-profile-section-img"
                                                                alt='all categories'
                                                            />
                                                            <div className="chat-profile-details word-break-all">
                                                                <h5>All Categories</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {categoryList.map((category) => (
                                                        <li key={category.category_id} className="chat-list p-1 cursor-pointer" onClick={() => handleCategoryChange(category)}>
                                                            <div className="chat-info">
                                                                <img
                                                                    src={category?.category_image_link || "./assets/img/home/business.svg"}
                                                                    className="img-fluid chat-profile-section-img"
                                                                    alt={category.category_image_link}
                                                                />
                                                                <div className="chat-profile-details word-break-all">
                                                                    <h5>{category.name}</h5>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <select
                                        style={{ cursor: "pointer" }}
                                        className="form-select custom-select"
                                        aria-label="Default select example"
                                        value={selectedCategory}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value=""disabled>All Categories</option>
                                        {
                                            categoryList && categoryList?.map((category) =>
                                                <option style={{ cursor: "pointer" }} key={category?.category_id} value={category?.category_id}> {category.icon} {category.name}</option>
                                            )
                                        }
                                    </select> */}
                                </li>
                                <li className="nav-item">
                                    <div className="input-search">
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => handleSearch(e)}
                                            placeholder="Search Courses"
                                            defaultValue={seach_val}
                                        />
                                        <span><img src="assets/img/icon-search.svg" className="img-fluid" /></span>
                                    </div>
                                </li>

                            </ul>
                            <form className="head-profile-notification">
                                <div className="btn-group notification-popup" style={{ float: "right" }}>
                                    <a className="btn-notifications" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false" >
                                        {/* onClick={() => viewNotifications()} */}
                                        <img src="assets/img/icon-notification.svg" className="img-fluid" />
                                        {/* className="n-count" */}
                                        <span className={NotificationList?.length > 0 ? "n-count" : ""} >{NotificationList && NotificationList?.map((e) => e?.totalNotification)}</span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-lg-end">
                                        <div className="chat-main-section">
                                            <div className="chat-body">
                                                <div className="chat-list">
                                                    <div className="chat-info">
                                                        {NotificationList && NotificationList.length > 0 ? (
                                                            <>
                                                                <img src="assets/img/header/icon-notification.svg" alt="notification" className="img-fluid chat-profile-section-img"
                                                                />
                                                                {NotificationList.map(({ title, message, insertdate }, index) => (
                                                                    <div key={index} className="chat-profile-details">
                                                                        <h5>{title}</h5>
                                                                        <p>{message}</p>
                                                                        {renderDateTime(insertdate)}
                                                                    </div>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <div className="chat-profile-details">
                                                                <h5>No notifications</h5>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* Show the "View all notifications" link only if there are notifications */}
                                                {
                                                    NotificationList && NotificationList.length > 0 && (
                                                        <button type="button" onClick={() => navigate('/viewnotification')} className="fs-15 fw-4 gray1 bg-transparent border-0 ">View all notifications</button>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    {/* )} */}
                                </div>
                                <div className="btn-group" style={{ float: "right" }}>
                                    <a className="btn-profile" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"><img src={userDetails?.profile_image !== "" ? userDetails?.profile_image_link : "assets/img/header/icon-my-profile.svg"} className="img-fluid rounded-circle" style={{ height: "30px", width: "30px" }} /> {userDetails?.first_name} {userDetails?.last_name}
                                        <img src="assets/img/icon-arrow-down-gray.svg" className="img-fluid" /></a>
                                    <div className="dropdown-menu dropdown-menu-lg-end">
                                        <div className="chat-main-section">
                                            <div className="chat-body">
                                                <a onClick={() => navigate('/myprofile')} className="chat-list cursor-pointer" style={{ cursor: "pointer" }}>
                                                    <div className="chat-info">
                                                        <img src={userDetails?.profile_image !== "" ? userDetails?.profile_image_link : "assets/img/header/icon-my-profile.svg"} className="chat-profile-section-img img-fluid rounded-circle" />
                                                        <div className="chat-profile-details">
                                                            <h5>My Profile</h5>
                                                            <p>Manage your profile</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a onClick={() => navigate('/progressreport')} className="chat-list" style={{ cursor: "pointer" }}>
                                                    <div className="chat-info">
                                                        <img src="assets/img/header/icon-progress.svg" className="img-fluid chat-profile-section-img" />
                                                        <div className="chat-profile-details">
                                                            <h5>Progress Report</h5>
                                                            <p>Check out your progress report.</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a onClick={() => navigate('/downloaded-lessions')} className="chat-list" style={{ cursor: "pointer" }}>
                                                    <div className="chat-info">
                                                        <img src="assets/img/header/icon-download.svg" className="img-fluid chat-profile-section-img" />
                                                        <div className="chat-profile-details">
                                                            <h5>Downloaded Lessons</h5>
                                                            <p>View lessons you've downloaded</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a onClick={() => handleShow6()} className="chat-list" style={{ cursor: "pointer" }}>
                                                    <div className="chat-info">
                                                        <img src="assets/img/header/icon-interests.svg" className="img-fluid chat-profile-section-img" />
                                                        <div className="chat-profile-details">
                                                            <h5>Interests</h5>
                                                            <p>Update your interests</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                {/* onClick={handleLogout} */}
                                                <a className="chat-list" href="#logout-profile" data-bs-toggle="modal" >
                                                    <div className="chat-info">
                                                        <img src="assets/img/header/icon-logout.svg" className="img-fluid chat-profile-section-img" />
                                                        <div className="chat-profile-details">
                                                            <h5>Logout</h5>
                                                            <p>Logout from account</p>
                                                        </div>
                                                    </div>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </nav>
            </header>

            <div className="delete_profile-popup">
                <div className="modal fade" id="logout-profile" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-center">
                            <div className="modal-body">
                                <div className="delet-popup-content">
                                    <img src="./assets/img/delet-popup-img.png" alt="" className="delet-popup-img" />
                                    <h3 className="fs-22 fw-7 dark black">Logout</h3>
                                    <p className="fs-16 fw-4 gray1">Are you sure you want to logout?</p>

                                    <button className="yes-delete" data-bs-dismiss="modal" onClick={handleLogout}>Yes, Logout</button>
                                    <a href="" className="fs-14 fw-5 gray1" data-bs-dismiss="modal">No, Thanks</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <InterestModal
                showModal6={showModal6}
                handleClose6={handleClose6}
            />
        </>
    )
}

export default CommonHeader;