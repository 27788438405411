import Cookies from "js-cookie";
import { Suspense, lazy } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import TermsOfUse from "../components/TermsOfUse";
import PrivacyPolicy from "../components/PrivacyPolicy";
import AboutUs from "../components/AboutUs";
import ContactUs from "../components/ContactUs";
// import ContactUs from "../components/ContactUs";

export const Loadable = (Component) => (props) => {
    return (
        <Suspense>
            <Component {...props} />
        </Suspense>
    );
};

const Home = Loadable(lazy(() => import("../pages/Home")));
const SignUp = Loadable(lazy(() => import("../pages/Authorization/SignUp")));
const Login = Loadable(lazy(() => import("../pages/Authorization/Login")));
const ForgotPassword = Loadable(lazy(() => import("../pages/Authorization/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("../pages/Authorization/ResetPassword")));
const CompleteProfile = Loadable(lazy(() => import("../pages/Authorization/CompleteProfile")));
const SelectInterests = Loadable(lazy(() => import("../pages/Authorization/SelectInterests")));
const AddSubscription = Loadable(lazy(() => import("../pages/Authorization/AddSubscription")));
const StripePayment = Loadable(lazy(() => import("../pages/Payment")));
const BillingInformation = Loadable(lazy(() => import("../pages/Authorization/BillingInformation")));
const Dashboard = Loadable(lazy(() => import("../pages/DashBoard")));
const MyCourses = Loadable(lazy(() => import("../pages/MyCourses")));
const LeaderBoard = Loadable(lazy(() => import("../pages/LeaderBoard")));
const Setting = Loadable(lazy(() => import("../pages/Settings")));
const CategoryWiseCourse = Loadable(lazy(() => import("../pages/CategoryWiseCourse")));
const FeaturedCourse = Loadable(lazy(() => import("../pages/FeaturedCourse")));
const CourseDetails = Loadable(lazy(() => import("../pages/CourseDetails")));
const SearchCategory = Loadable(lazy(() => import("../pages/SearchCategory")));
const Notification = Loadable(lazy(() => import("../pages/Notification")));
const ProgressReport = Loadable(lazy(() => import("../pages/ProgressReport")));
const DownloadLession = Loadable(lazy(() => import("../pages/DownloadLession")));

const Practice1 = Loadable(lazy(() => import("../pages/Practice-1")));
const GreatJob = Loadable(lazy(() => import("../pages/GreatJob")));

const Router = () => {
    const token = Cookies.get('tokenCA');
    const isInterest = Cookies.get('isInterest');

    return (
        <Routes>
            <Route element={token && isInterest === "true" ? <Navigate to='/dashboard' /> : <Outlet />}>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Home />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/complete-profile" element={<CompleteProfile />} />
                <Route path="/select-interests" element={<SelectInterests />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/cognitive/change-password/:token" element={<ResetPassword />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                <Route path="/about_us" element={<AboutUs />} />
                <Route path="/contact_us" element={<ContactUs />} />
            </Route>

            <Route element={!token ? <Navigate to='/login' /> : <Outlet />} >
                
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/my-courses" element={<MyCourses />} />
                <Route path="/leaderboard" element={<LeaderBoard />} />
                <Route path="/categorywisecourse" element={<CategoryWiseCourse />} />
                <Route path="/featuredcourses" element={<FeaturedCourse />} />
                <Route path="/coursedetails" element={<CourseDetails />} />
                <Route path="/searchcategory" element={<SearchCategory />} />
                <Route path="/viewnotification" element={<Notification />} />
                <Route path="/progressreport" element={<ProgressReport />} />
                <Route path="/downloaded-lessions" element={<DownloadLession />} />
                <Route path="/practice-1" element={<Practice1 />} />
                <Route path="/greatjob" element={<GreatJob />} />
                <Route path="/myprofile" element={<Setting />} />
                <Route path="/change-password" element={<Setting />} />
                <Route path="/payment-method" element={<Setting />} />
                <Route path="/about-us" element={<Setting />} />
                <Route path="/termsofuse" element={<Setting />} />
                <Route path="/contact-us" element={<Setting />} />
                <Route path="/privacy-policy" element={<Setting />} />
                <Route path="/help-faq" element={<Setting />} />

            </Route>
        </Routes>
    )
}

export default Router;

{/* <Route path="/add-subscription" element={<AddSubscription />} />
                <Route path="/stripe-payment" element={<StripePayment />} />
                <Route path="/billing-information" element={<BillingInformation />} /> */}