import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const CountryCodeSelect = ({ control, name, countryList, userDetails = null }) => {

    // const options = countryList.map((country) => ({
    //     const formattedValue = option.value.startsWith('+') ? option.value : `+${option.value}`;
    //     value: country.phonecode,
    //     label: `${country.phonecode} ${country.name}`,
    // }));

    const options = countryList.map((country) => {

        // Ensure the phone code starts with a '+'
        const formattedValue = country.phonecode.startsWith('+') ? country.phonecode : `+${country.phonecode}`;

        return {
            value: country.phonecode,
            label: `${formattedValue} ${country.name}`,
        };
    });

    const initialCountryCode = userDetails?.country_code || '+1-242';

    // Set initial selected country based on userDetails or default to "+1"
    const [selectedCountry, setSelectedCountry] = useState(initialCountryCode);

    useEffect(() => {
        setSelectedCountry(initialCountryCode);
    }, [userDetails]);


    // Handle country change
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption?.value);
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={initialCountryCode} // Set default value for react-hook-form
            rules={{ required: "Please select country code" }}
            render={({ field, fieldState: { error } }) => (
                <>
                    <Select
                        {...field}
                        options={options}
                        value={options.find(option => option.value === selectedCountry)}
                        onChange={(selectedOption) => {
                            field.onChange(selectedOption.value);  // Pass the value, not the whole object
                            handleCountryChange(selectedOption);   // Call your custom handler (optional)
                        }}
                        placeholder="Select"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        formatOptionLabel={(option, { context }) => {
                            // Conditionally add the "+" sign only if not already present
                            const formattedValue = option.value.startsWith('+') ? option.value : `+${option.value}`;
                            return context === 'menu' ? `${option.label}` : formattedValue;
                        }} // Show full label in dropdown, and only code after selection
                        // formatOptionLabel={(option, { context }) => 
                        //     context === 'menu' ? `${option.label}` : `+${option.value}`
                        // } // Show full label in dropdown, and only code after selection
                        onBlur={field.onBlur}  // Required to register onBlur for form validation
                    />
                    {error && <p role="alert" className="text-danger mobile-error">{error.message}</p>}
                </>
            )}
        />
    );
};

export default CountryCodeSelect;