import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getDownloadDetails = createAsyncThunk("DownloadList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.downloadList();
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
    DownloadList: {
    data: {},
    error: null,
  }
};

const downloadSlice = createSlice({
  name: "Download",
  initialState,
  reducers: {
    setDownloadDetails: (state) => {
      state.DownloadList.data = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDownloadDetails.fulfilled, (state, action) => {
        state.DownloadList.data = action.payload.data;
      })
      .addCase(getDownloadDetails.rejected, (state, action) => {
        state.DownloadList.error = action.error.message;
      })
  }
});

export const { setDownloadDetails } = downloadSlice.actions;
export default downloadSlice.reducer;
