import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getLeaderboardDetails = createAsyncThunk(
    "leaderboardDetails",
    async ({ page, type }, { dispatch }) => {
        try {
            dispatch(setLoader(true));
            const data = await API.leaderboardDetails({ page, type });
            dispatch(setLoader(false));
            return data;
        } catch (error) {
            dispatch(setLoader(false));
            throw error;
        }
    }
);

const initialState = {
    leaderboardDetails: {
        data: [], 
        error: null,
    },
};
const leaderboardSlice = createSlice({
    name: "LEADERBOARD",
    initialState,
    reducers: {
        setLeaderboardDetails: (state, action) => {
            state.leaderboardDetails.data = action.payload; 
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLeaderboardDetails.pending, (state) => {
                state.leaderboardDetails.loading = true;
                state.leaderboardDetails.error = null;
            })
            .addCase(getLeaderboardDetails.fulfilled, (state, action) => {
                state.leaderboardDetails.loading = false;
                state.leaderboardDetails.data = action.payload.data;
            })
            .addCase(getLeaderboardDetails.rejected, (state, action) => {
                state.leaderboardDetails.loading = false;
                state.leaderboardDetails.error = action.error.message;
            });
    },


});

export const { setLeaderboardDetails } = leaderboardSlice.actions;
export default leaderboardSlice.reducer;

