import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ScreenSuccess = ({ setIsOpen,handleBillClose }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setIsOpen(false);
    handleBillClose()
    navigate('/dashboard');
  };

  return (
    <div>
      <Modal show={true} onHide={handleClose} centered>
        <Modal.Body>
          <div className="theme-small-modal">
            <img src="assets/img/thanku-hand.png" className="img-fluid" alt="Thank you" />
            <h2>Thank you!</h2>
            <p>You've subscribed. Enjoy!</p>
            <button type="button" className="btn btn-theme w-100 mt-5" onClick={handleClose}>Ok</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ScreenSuccess;
