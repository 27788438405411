import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

export const getCourseDetails = createAsyncThunk("CourseDetails", async ({page,category_id,course_id}, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.courseDetails({page,category_id,course_id});
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  CourseDetails: {
    data: {},
    error: null,
  }
};

const courseSlice = createSlice({
  name: "Course",
  initialState,
  reducers: {
    setCourseDetails: (state) => {
      state.CourseDetails.data = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourseDetails.fulfilled, (state, action) => {
        state.CourseDetails.data = action.payload.data;
      })
      .addCase(getCourseDetails.rejected, (state, action) => {
        state.CourseDetails.error = action.error.message;
      })
  }
});

export const { setCourseDetails } = courseSlice.actions;
export default courseSlice.reducer;
