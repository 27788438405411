import React, { Suspense } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/index";
import './App.css';
import { useSelector } from 'react-redux';
import Spinner from './components/Spinner';
import Cookies from 'js-cookie';

const App = () => {
  const { isLoading } = useSelector((state) => state.master);
  
  return (
    <>
    {isLoading && <Spinner />}
      <Router>
          <Routes />
      </Router>
    </>
  );
}


export default App;
